import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import ProTable from "@/components/ProTable/index.vue";
import ProForm from "@/components/ProForm/index.vue";

import '@/assets/css/simpleCss.scss'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/element-variables.scss'

import loadMore from '@/directives/select'


// 全局table组件
Vue.component('ProTable', ProTable)
Vue.component('ProForm', ProForm)

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(loadMore)



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
