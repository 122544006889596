<template>
  <div class="menu-wrapper">
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#545c64"
      text-color="#fff"
      unique-opened
      active-text-color="#6cceae">

      <!-- 菜单上面的logo -->
      <menuLogo />

      <template v-for="(menu, index) in menuList" >
        <template v-if="menu.children && menu.permission">
          <subMenu :sub-menu="menu" />
        </template>
        <el-menu-item @click="menuClick(menu)" v-else :index="menu.name">
          <i :class="menu.icon"></i>
          <span slot="title">{{ menu.title }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import menuLogo from '../menuLogo.vue'
import { menuList } from './index'
import subMenu from './components/subMenu.vue'
export default {
  name: "index",
  components: { menuLogo, subMenu },
  data() {
    return {
      menuList
    }
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    menuClick(menu) {
      this.$router.push({
        path: menu.path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu-wrapper {
    width: 240px;
    height: 100%;

    .el-menu {
      height: 100%;
      //overflow: hidden;

     .el-submenu, .el-menu-item {
        text-align: left;
      }
    }
  }
</style>
