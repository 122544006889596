import request from '../axios'

export const testApi = () => {
  return request.get({
    url: 'http://localhost:3000/test',
    data: {
      name: '张三',
      id: 'zs'
    }
  }, {
    isUrlParams: false
  })
}
