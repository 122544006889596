export const menuList = [
  // {
  //   path: '/demo',
  //   name: 'demo',
  //   title: '菜单一',
  //   icon: 'el-icon-location',
  //   permission: true,
  //   children: [
  //     {
  //       path: '/demo1',
  //       name: 'demo1-1',
  //       title: '菜单1-1',
  //       permission: true,
  //       children: [
  //         {
  //           path: '/demo1',
  //           name: 'demo1-1-1',
  //           title: '菜单1-1-1',
  //           permission: true,
  //         }
  //       ]
  //     },
  //     {
  //       path: '/',
  //       name: 'demo1-2',
  //       title: '菜单1-2',
  //       permission: true,
  //     }
  //   ]
  // },
  {
    path: '/demo/table',
    name: 'table',
    title: '表格',
    icon: 'el-icon-location',
    permission: false,
  },
  {
    path: '/demo/form',
    name: 'form',
    title: '表单',
    icon: 'el-icon-location',
    permission: false,
  },
  {
    path: '/demo/component',
    name: 'component',
    title: '组件',
    icon: 'el-icon-location',
    permission: false,
  },
  {
    path: '/login',
    name: 'login',
    title: '登录',
    icon: 'el-icon-location',
    permission: false,
  }
]
