export default {
  state: {
    userInfo: {
      name: 'Jax'
    }
  },
  mutations: {
    setUserInfo(state, userInfo){
      state.userInfo = userInfo
    },
  },
  actions: {}
}
