<template>
  <div class="com-tab">
    <div class="dataTable">
      <div class="tool_bar">
        <slot name="toolBar"></slot>
      </div>
      <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData"
          style="width: 100%;"
          :show-header="showHeader"
          :max-height="maxHeight"
          border
          :header-cell-style="{ background: '#f1f1f1' }"
          v-bind="$attrs"
          v-on="$listeners">

        <!--    表格内容    -->
          <template v-for="(col, colIndex) in columns" >
            <el-table-column v-if="['index', 'selection'].includes(col.type)"
                             v-bind="getAttr(col)" :type="col.type"></el-table-column>

            <el-table-column v-else-if="col.slotName === 'rowOperation'"
                             v-bind="getAttr(col)"
                             class="operate_wrapper">
              <template #default="{row, column, $index }" >
                <span
                  v-for="(operate, index) in col.operationList"
                  class="operate_btn"
                  :class="{
                        [operate.type]: operate.type
                      }"
                  :key="index"
                  @click="operate.click(row, column, $index)">
                      {{ operate.title }}
                </span>
              </template>
            </el-table-column>

            <el-table-column v-else-if="col.slotName"  v-bind="getAttr(col)">
              <template #default="{row, column, $index }" >
                <slot :name="col.slotName" :row="row" :column="column" :$index="$index"></slot>
              </template>
            </el-table-column>

            <el-table-column v-else v-bind="getAttr(col)">
              <!--    常规字段渲染    -->
              <template #default="{row, column, $index }" >
                <span>{{ row[col.prop] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
    </div>

    <!--  分页  -->
    <div
        v-show="total"
        v-if="!hiddenPage"
        class="com-table-page"
        style="text-align: right; margin-top: 20px">
        <el-pagination
          background
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          :layout="layout"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>
</template>
<script>
  import { cloneDeep } from "lodash";

  export default {
      name : 'PageTable',
      props: {
        pageSizes: {
          // 分页规则
          type   : Array,
          default: () => {
            return [10, 15, 20, 30, 50]
          }
        },
        pageTotal: {
          type   : Number,
          default: 0
        },
        pageSize: {
          type   : Number,
          default: 10
        },
        // 是否显示头部
        showHeader: {
          type   : Boolean,
          default: true
        },
        // 是否显示分页
        hiddenPage: {
          type   : Boolean,
          default: false
        },
        // 请求方法，promise
        requestMethod: {
          type   : Function,
          default: () => ({})
        },
        // 列配置
        columns: {
          type: Array,
          default: () => ([])
        },
        // 表格最大高度
        maxHeight: {
          type   : String,
          default: 'auto'
        },
        // 是否初始化的时候不加载
        initFalse: {
          type   : Boolean,
          default: false
        },
        // 查询参数
        params: {
          // 数据
          type   : Object,
          default: () => {
            return {}
          }
        },
        data: {
          type   : Array,
          default: () => {
            return []
          }
        },
        // 分页参数是否放在body体内
        isBodyPage: {
          type   : Boolean,
          default: false
        }
      },
      data () {
        return {
          // 获取到的数据
          tableData: [],
          // 当前页数
          currentPage: 1,
          // 共计0
          total: 0,
          // 是否显示加载中
          loading: false,
          // 多选的数据 当前选中数据
          multipleSelection: [],
          // 单选的数据
          selectionData: {},
          // 接口数据
          responseData: {},
          slots: '',
          layout: 'total, sizes, prev, pager, next, jumper',
        }
      },
      watch: {
        params (val) {
          if(!this.initFalse && this.data.length=== 0) {
            this.reloadData(val)
          }
        },
        data () {
          this.tableData = this.data
        }
      },
      mounted () {
        this.slots = this.$slots
        this.total = this.pageTotal
        if(!this.initFalse && this.data.length=== 0) {
          this.getPageData()
        }
        if(this.data.length>0) {
          this.tableData = this.data
        }
      },
      methods: {
        // 获得分页后的数据
        getPageData (obj) {
          this.currentPage = 1
          this.reloadData(obj || this.params)
        },

        select (selection, row) {
          this.$emit('select', selection, row)
        },
        // 参数查询
        reloadData (params) {
          this.loading = true
          const queryParams = { ...params }
          if(!this.hiddenPage) {
            queryParams.pageNum = this.currentPage
            queryParams.pageSize = this.pageSize
          }
          this.requestMethod(queryParams).then(res => {
            this.doTableData(res)
          }).finally(() => {
            this.loading = false
          })
        },
        doTableData (res) {
          this.responseData = res
          if(Array.isArray(res)) {
            this.tableData = res
          } else if(res.list) {
            this.tableData = res.list
          } else if(res?.data?.list) {
            this.tableData = res.data.list
          } else {
            this.tableData = []
          }
          this.total = parseInt(res.total)
          this.$emit('totalSize', this.total)
          this.$emit('getResponseData', res)
        },
        getAttr(column) {
          return cloneDeep(column)
        },
        // 修改页的大小
        handleSizeChange (val) {
          this.pageSize = val
          this.reloadData(this.params)
          this.$emit('size-change', val)
        },
        // 翻页的时候触发
        handleCurrentChange: function (val) {
          this.currentPage = val
          this.reloadData(this.params)
          this.$emit('current-change', val)
        },
      }
    }
</script>
<style lang="scss">
    .operate_btn {
      padding: 0 4px;
      cursor: pointer;

      &.primary {
        color: $--main-color;
      }
      &.warning {
        color: $--color-warning;
      }
      &.danger {
        color: $--color-danger;
      }
      &.info {
        color: $--color-info;
      }
    }

    .tool_bar {
      margin-bottom: 20px;

      .el-button {
        padding: 8px 15px !important;
      }
    }


    .el-table thead,
    .el-table tbody {
      color: #333333;
      font-size: 14px;
    }
    /* 表格边框色 */
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      background-color: #e1e8ee;
    }

    .el-table--border,
    .el-table--group {
      border-color: #e1e8ee;
    }

    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 1px solid #e1e8ee;
    }

    .el-table--border th,
    .el-table--border th.gutter:last-of-type {
      border-bottom: 1px solid #e1e8ee;
    }

    .el-table--border td,
    .el-table--border th {
      border-right: none;
    }

    .com-tab .el-radio__label {
      display: none !important;
    }
  </style>
