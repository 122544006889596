import Vue from "vue";
import Vuex from "vuex";
import getters from "@/store/getters";
import testStore from "@/store/testStore";
import vuexPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters,
  modules: {
    testStore
  },
  plugins: [vuexPersistedstate()]
});
