<template>
  <div class="login_wrapper">
    <div class="login_form">
      <h3 class="title">登录</h3>
      <el-input v-model="formData.userName" prefix-icon="el-icon-user-solid" placeholder="请输入用户名"></el-input>
      <el-input
        v-model="formData.passWord"
        prefix-icon="el-icon-lock"
        type="password"
        placeholder="请输入密码"
        show-password></el-input>
      <el-button @click="loginClick" class="login_btn" type="primary">登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      formData: {
        userName: '',
        passWord: ''
      }
    }
  },
  methods: {
    loginClick() {
      this.$router.push({
        path: '/'
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.login_wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $window-background;

  > .login_form {
    width: 400px;
    height: 250px;
    padding: 30px;
    background: #fff;
    border-radius: 12px;

    > .title {
      text-align: center;
    }

    > .el-input {
      margin-bottom: 20px;
    }

    > .login_btn {
      width: 100%;
    }
  }
}
</style>
