import Vue from "vue";
import VueRouter from "vue-router";
import LAYOUT from '../views/layout/index.vue'
import Login from '@/views/login/index.vue'
import { asyncRouter } from './asyncRouter'
import Table from "@/views/table/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    component: LAYOUT,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  ...asyncRouter
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});
router.beforeEach((to, from, next) => {

  console.log(router);
  next()
})

let originPush = VueRouter.prototype.push
let originReplace = Vue.prototype.originReplace

VueRouter.prototype.push = function (location,res,rej) {
  if (res && rej ) {
    originPush.call(this,location,res,rej)
  } else {
    originPush.call(this,location,()=>{},()=>{})
  }
}

VueRouter.prototype.replace = function(location ,res,rej) {
  if (res && rej) {
    originReplace.call(this,location,res,rej)
  } else {
    originReplace.call(this,location,() => {},() => {})
  }
}
export default router;
