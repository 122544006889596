<template>
  <div class="app-wrapper">
    <NavMenu />
    <div class="content_wrapper">
      <Breadcrumb />
      <AppMain />
    </div>
  </div>
</template>

<script>
import NavMenu from './components/navMenu/index.vue'
import AppMain from './components/AppMain.vue'
import Breadcrumb from './components/breadcrumb.vue'
export default {
  name: "index",
  components: { NavMenu, AppMain, Breadcrumb }
}
</script>

<style lang="scss" scoped>
  .app-wrapper {
    display: flex;
    height: 100%;

    > .content_wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: calc(100% - 240px);
    }
  }
</style>
