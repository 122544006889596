const Input = () => import('./formComponents/Input.vue')
const Select = () => import('./formComponents/Select.vue')
const RadioGroup = () => import('./formComponents/RadioGroup.vue')
const CheckboxGroup = () => import('./formComponents/CheckboxGroup.vue')
const InputNumber = () => import('./formComponents/InputNumber.vue')
const Switch = () => import('./formComponents/CustomSwitch.vue')
const TimeSelect = () => import('./formComponents/TimeSelect.vue')
const DatePicker = () => import('./formComponents/DatePicker.vue')
const Upload = () => import('./formComponents/Upload.vue')


export default {
  Input,
  Select,
  Switch,
  Upload,
  TimeSelect,
  DatePicker,
  RadioGroup,
  InputNumber,
  CheckboxGroup,
}
