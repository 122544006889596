import LAYOUT from "@/views/layout/index.vue";
export const asyncRouter = [
  {
    path: '/demo',
    name: 'demo',
    redirect: 'noDirect',
    component: LAYOUT,
    children: [
      {
        path: "table",
        name: "table",
        component: () => import('../views/table/index.vue'),
        meta: {
          title: '表格'
        }
      },
      {
        path: "form",
        name: "form",
        component: () => import('../views/form/index.vue'),
        meta: {
          title: '表单'
        }
      },
      {
        path: "component",
        name: "component",
        component: () => import('../views/componentsDemo/index.vue'),
        meta: {
          title: '组件'
        }
      }
    ]
  }
]
