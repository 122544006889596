<template>
  <div class="main-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "AppMain",

}
</script>

<style lang="scss" scoped>
  .main-wrapper {
    //overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
    border: 10px solid #ecebeb;
    padding: 10px;
    flex: 1;
    box-sizing: border-box;
  }
</style>
