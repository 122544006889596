<template>
  <el-submenu v-if="subMenu.permission" :index="subMenu.name">
    <template  slot="title">
      <i :class="subMenu.icon"></i>
      <span>{{ subMenu.title }}</span>
    </template>

    <template v-for="menu in subMenu.children">
      <template v-if="menu.children">
        <subMenu :sub-menu="menu"/>
      </template>
      <template v-else>
        <el-menu-item @click="menuClick(menu)" v-if="menu.permission" :index="menu.name">
          <i :class="menu.icon"></i>
          <span slot="title">{{ menu.title }}</span>
        </el-menu-item>
      </template>
    </template>
  </el-submenu>
</template>

<script>
export default {
  props: {
    subMenu: {
      type: Object
    }
  },
  name: "subMenu",
  data() {
    return {

    }
  },
  methods: {
    menuClick(menu) {
      this.$router.push({
        path: menu.path
      })
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
