import axios from "axios";
import { apiCodes } from "@/axios/apiCodes";

const ENV = process.env.VUE_APP_BASE_API_URL

const instance = axios.create({
  baseURL: '',
  timeout: 10000,
  withCredentials: false
})

instance.interceptors.request.use((config) => {
  console.log(config)
  return config
},(err) => {
  console.log(err)
})

instance.interceptors.response.use((response) => {
  const config = response.config

  if(response.status === 200) {

    // 200 接口请求成功
    if(response.data.code === apiCodes.SUCCESS_CODE) {

    }
  }
  return response.data
})

export default instance
