<template>
  <div class="form-wrapper" :class="{ 'flex between': tableSearch }">
    <el-form
      ref="elFormRef"
      class="el_form_wrapper"
      :model="_formData"
      :class="{table_search: tableSearch}"
      :rules="rules"
      :label-width="labelWidth"
    >
      <el-form-item :label="formItem.label"
                    :prop="formItem.prop"
                    :required="formItem.required"
                    v-for="(formItem, index) in configsClone"
                    :key="index"
                    :style="{
                      width: `${100/column}%`
                    }">
        <slot v-if="formItem.slotName" :name="formItem.slotName" ></slot>
        <component v-else
                   v-model="_formData[formItem.prop]"
                   :is="CustomComponents[formItem.componentName]"
                   v-bind="formItem.attr"
                   v-on="formItem.events"></component>
      </el-form-item>
    </el-form>
    <div class="operate_box">
      <slot name="footer">
        <div class="con">
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </div>
        <div v-if="showCollapse" class="icon_wrapper" @click="collapseClick">
          <i class="el-icon" :class="{ 'el-icon-arrow-down': collapse, 'el-icon-arrow-up': !collapse }"></i>
          <span>{{ collapse ? '展开' : '收起' }}</span>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import CustomComponents from './componentTypes'
import { cloneDeep } from 'lodash'
export default {
  name: "index",
  props: {
    configs: {
      type: Array,
      default: () => ([])
    },
    rules: {
      type: Object,
      default: () => {}
    },
    labelWidth: {
      type: [Number, String],
      default: 'auto'
    },
    value: {
      type: Object,
      default: () => ({})
    },
    // 每列几行
    column: {
      type: Number,
      default: 1
    },
    tableSearch: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'configs': {
      handler(v) {
        if(v.length > this.column && this.tableSearch) {
          this.showCollapse = true
          this.collapse = true
          this.configsClone = v.slice(0, this.column)
        } else {
          this.collapse = false
          this.configsClone = cloneDeep(v)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    _formData: {
      get(){
        if(!this._formDataCache) this._formDataCache = cloneDeep(this.value)
        return this.value
      },
      set(v) {
        this.$emit('input',v)
      }
    }
  },
  mounted() {
    // console.log(this.$slots)
  },
  data() {
    return {
      CustomComponents,
      // 是否开启收起展开操作
      showCollapse: false,
      _formDataCache: null,
      // 是否展开
      collapse: false,
      configsClone: [],
    }
  },
  methods: {
    handleSearch() {
      this.$emit('search')
    },
    handleReset() {
      this._formData = cloneDeep(this._formDataCache)
      this.$emit('search')
    },
    collapseClick() {
      if(this.collapse) {
        this.collapse = false
        this.configsClone = cloneDeep(this.configs)
      } else {
        this.collapse = true
        this.configsClone = this.configs.slice(0, 3)
      }
    },
    validate(cb) {
      this.$refs.elFormRef.validate((valid) => {
       if(cb) cb(valid)
      })
    },
    clearValidate() {
      this.$refs.elFormRef.clearValidate()
    },
    validateField(fields, cb) {
      this.$refs.elFormRef.validateField(fields, errorMessage => {
        if(cb) cb(errorMessage)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  ::v-deep .el-button {
    padding: 8px 15px !important;
  }

  .el-input-number {
    line-height: 34px;
  }

  .el_form_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .el-form-item {
      display: flex;

      ::v-deep .el-form-item__content {
        flex: 1;
        margin-left: 0 !important;

        > div {
          width: 100%;
        }
      }
    }


    &.table_search {
      justify-content: space-between;
      width: 80%;

      .el-form-item {
        display: flex;

        ::v-deep .el-form-item__content {
          flex: 1;
          padding-right: 60px;

          > div {
            width: 100%;
          }
        }
      }
    }

  }

  .operate_box {
    align-self: flex-start;
    display: flex;
    align-items: center;


    .icon_wrapper {
      display: flex;
      align-items: center;
      padding-left: 10px;
      cursor: pointer;
      font-size: 14px;
      color: $--main-color;

      > .el-icon {
        font-size: 16px;
      }
    }
  }
</style>
