<template>
  <div>
    <ProForm v-model="formData" :configs="configs" :column="3" table-search @search="handleSearch"></ProForm>
    <ProTable :request-method="requset" :columns="columns" >
    <!--  表格上方操作按钮    -->
      <template #toolBar>
        <el-button @click="handleAdd">新增</el-button>
        <el-button type="primary" @click="handleEdit">编辑</el-button>
      </template>
    <!--   表格字段插槽   -->
      <template #slotTest="{ row }">
        <div style="color: red">这是插槽{{ row }}</div>
      </template>
    </ProTable>
  </div>
</template>

<script>
import { testApi } from "@/api/common";
import useGrayscalePublishModel from './grayscalePublish.js'
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "index",
  data(){
    return {

      columns: [
        {
          width: 80,
          type: 'selection',
        },
        {
          width: 80,
          label: '序号',
          type: 'index'
        },

        {
          width: 200,
          prop: 'name',
          label: '名称2'
        },
        {
          width: 500,
          prop: 'name',
          label: '名称3'
        },
        {
          width: 500,
          prop: 'name',
          label: '名称4',
          formatter: (text) => {
            return text.name + 'formatter'
          }
        },
        {
          width: 500,
          prop: 'name',
          label: '名称5',
          slotName: 'slotTest'
        },
        {
          label: '操作',
          width: 200,
          fixed: 'right',
          slotName: 'rowOperation',
          operationList: [
            {
              title: '编辑',
              type: 'primary',
              click: (row) => {
                console.log(row)
              }
            },
          ]
        },
      ],
      configs: [
        {
          prop: 'select',
          label: '下拉',
          componentName: 'Select',
          attr: {
            labelWidth: 'auto',
            placeholder: 'ddddd',
            clearable: true,
            options: [
              {
                label: '1',
                value: '1'
              },
              {
                label: '2',
                value: '2'
              }
            ]
          },
          events: {
            change: (v) => {
              console.log(v)
            }
          }
        },
        {
          prop: 'input',
          label: '输入',
          componentName: 'Input',
          attr: {
            placeholder: 'ddddd',
            clearable: true,
          },
          events: {
            input: (v) => {
              // console.log(v)
            }
          }
        },
        {
          prop: 'input',
          label: '输入',
          componentName: 'Input',
          attr: {
            placeholder: 'ddddd',
            clearable: true,
          },
          events: {
            input: (v) => {
              // console.log(v)
            }
          }
        },
        {
          prop: 'input',
          label: '输入',
          componentName: 'Input',
          attr: {
            placeholder: 'ddddd',
            clearable: true,
          },
          events: {
            input: (v) => {
              // console.log(v)
            }
          }
        }
      ],
      formData: {
        input: ''
      }
    }
  },

  watch: {
    'formData':{
      handler(v) {
        // console.log(v)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    // testApi()
    console.log(this.userInfo)
  },
  methods: {
    ...mapMutations(['setUserInfo']),

    handleAdd() {
      this.setUserInfo({ name: '二麻' })
      console.log(this.userInfo)
    },
    handleSearch() {
      console.log(this.formData)
    },
    requset() {
      return new Promise((resolve, reject) => {
        resolve({ list: [{ name: 222 }, { name: 2122 }], total: 100 })
      })
    },
    async handleEdit() {
      const [ openModel ] = useGrayscalePublishModel({
        confirm: (e) => {
          console.log(e)
        }
      })
      openModel()
      // const res = await testApi()
      // console.log(res)
    }
  }
}
</script>

<style scoped>

</style>
