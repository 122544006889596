const maskStyle = `
  width: 100%; 
  height: 100%; 
  position: fixed; 
  top: 0;
  left: 0;
  background: #000; 
  opacity: .55; 
  z-index: 99;
`
const modelStyle = `
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 20px;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  z-index: 100;
`
const inputStyle = `
  height: 28px; 
  width: 240px; 
  outline: none;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  text-indent: 0.5em;
`
const btnStyle = `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
`
const cancelStyle = `
  background: #fff;
  color: #606266;
  border: 1px solid #dcdfe6;
`
const confirmStyle = `
  background: #6cceae;
  color: #fff;
`

/**
 * 使用方式
 * @param config
 * @returns {(function(): void)[]}
 */
//
// const [ openModel ] = useGrayscalePublishModel({
//   confirm: (data) => {
//     console.log(data)
//   }
// })

function useGrayscalePublishModel(config) {
  let body = document.querySelector('body')
  let mask = null
  let model = null

  const generatorModel = () => {
    mask = document.createElement('div')
    mask.setAttribute('class', 'mask')
    mask.style.cssText = maskStyle

    model = document.createElement('div')
    model.setAttribute('class', 'grayscale-publish-model')
    model.style.cssText = modelStyle

    model.innerHTML = `
      <div class="header" style="display: flex; justify-content: space-between; margin-bottom: 30px; color: #606266">
        <div class="title"> 灰度发布 </div>
        <div class="close" style="cursor: pointer; padding: 0 4px"> x </div>
      </div>
      <div class="mode_content" style="flex: 1">
        <div class="form">
          <div class="version">
            <div style="display: flex; align-items: center">
              <div class="label" style="font-size: 14px; width: 70px; color: #606266;">灰度版本</div>
              <input type="number" id="version" placeholder="请填写灰度版本号" style="${inputStyle}"></input>
            </div>
            <div class="version_rule_message" style="font-size: 14px; color: red; margin-left: 70px">请填写灰度版本</div>
          </div>
        </div>
      </div>
      <div class="footer" style="display: flex; flex-direction: row-reverse">
        <button class="confirm" id="confirm" style="${ btnStyle + confirmStyle }">确定</button>
        <button class="cancel" id="cancel" style="${ btnStyle + cancelStyle }">关闭灰度</button>
      </div>
    `
  }

  const addEventListener = () => {
    const close = document.querySelector('.grayscale-publish-model .close')
    close.addEventListener('click', (e) => {
      e.preventDefault()
      closeModel()
    })

    const version = document.querySelector('#version')
    version.addEventListener('input', (e) => {
      e.preventDefault()
      const ruleMessage = document.querySelector('.version_rule_message')
      ruleMessage.style.display = e.target.value.trim() ? 'none' : 'block'
    })

    const cancel = document.querySelector('#cancel')
    cancel.addEventListener('click', (e) => {
      e.preventDefault()
      closeModel()
    })

    const confirm = document.querySelector('#confirm')
    confirm.addEventListener('click', (e) => {
      e.preventDefault()
      closeModel()
    })
  }

  const openModel = () => {
    body.appendChild(mask)
    body.appendChild(model)
    addEventListener()
  }

  const closeModel = () => {
    if(Object.prototype.toString.call(config.confirm) === '[object Function]') {
      const input = document.querySelector('#version')
      config.confirm({ version: input.value})
    } else {
      throw Error('please set property confirm is function')
    }

    body.removeChild(mask)
    body.removeChild(model)
  }

  generatorModel()
  return [ openModel ]
}
export default useGrayscalePublishModel
