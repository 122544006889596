<template>
  <div class="breadcrumb_wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="bread in routerList" :key="bread.path" :to="{ path: bread.path }">
        {{ bread.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>


    <div class="user_info flex between item-center">
      <el-dropdown @command="handleCommand" trigger="click">
        <span class="el-dropdown-link flex between item-center">
          <div class="user_name">超级管理员</div>
          <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
export default {
  name: "breadcrumb",
  data() {
    return {
      routerList: []
    }
  },
  watch: {
    '$route': {
      handler(v) {
        this.routerList = v.matched.filter(item => (item.meta && item.meta.title))
          .map(v => {
            return { title: v.meta.title,
              path: v.path
            }
          })
        this.routerList.unshift({ title: '首页', path: '/' })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleCommand(e) {
      if(e === 'logout') {
        this.$confirm('确定退出当前账号吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$router.push('/login')
        }).catch(() => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .breadcrumb_wrapper {
    height: 60px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $--border-color;

    .user_info {

      .el-dropdown-link {
        cursor: pointer;

        .user_name {
          margin-right: 4px;
        }
      }
    }
  }
</style>
