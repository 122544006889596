<template>
  <div class="menu-logo">
    VUE-ADMIN-TEMPLATE
  </div>
</template>

<script>
export default {
  name: "menuLogo"
}
</script>

<style lang="scss" scoped>
  .menu-logo {
    color: #FFF;
    text-align: center;
    margin: 50px 0;
  }
</style>
